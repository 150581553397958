.videoWrapper {
    display: grid;
    grid-gap: 0px;
    grid-template-columns: repeat(auto-fill, 450px);
}

.box {
    padding: 0px;
    display: block;
    position: relative;
}

.v1 {
    margin-right: 40px;
    margin-bottom: 40px;
    width: 409px;
}

.v2 {
    margin-bottom: 40px;
    width: 409px;
}

#video {
    margin: 0 auto;
    align-content: center;
}

#video div img {
    width: 409px;
    border: 1px solid #969B9D;
}


@media only screen and ( max-width: 555px) {
    .v1 {
        margin-right: 0px;
        width: 100%;
        margin-bottom: 20px;
    }

    .v2 {
        width: 100%;
        margin-bottom: 20px;
    }
    
    .videoWrapper {
        display: grid;
        grid-gap: 0px;
        grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    }
    #video div img {
        width: 100%;
    }
}