#footer {
    opacity: 0;
}

.footer {
    margin: 0 auto;
    padding-top: 0px;
    padding-bottom: 40px;
    align-content: center;
    font-family: 'Helvetica', sans-serif;
    font-size: 10px;
    line-height: 13px;
    letter-spacing: .15em;
    font-weight: 500;
    font-style: normal;
    color: #969B9D;
    width: 90%;
}

@media only screen and ( max-width: 600px) {
    .footer {
        padding-top: 20px;
    }
}