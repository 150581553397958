.aboutWrapper {
    display: grid;
    grid-gap: 0px;
    grid-template-columns: repeat(auto-fit, 250px minmax(250px, 1fr));
}

#about {
    margin: 0 auto;
    align-content: center;
    font-family: 'Helvetica', sans-serif;
    font-size: 10px;
    line-height: 13px;
    letter-spacing: .15em;
    font-weight: 500;
    font-style: normal;
    color: #969B9D;
}

#about div img {
    width: 230px;
    border: 1px solid #969B9D;
}

.box {
    padding: 0px;
    display: block;
    position: relative;
}

.b {
    margin-right: 0px;
    margin-bottom: 40px;
    margin-top: -2px;
}

.a {
    margin-right: 20px;
    margin-bottom: 40px;
}

@media only screen and ( max-width: 555px) {
    .a {
        margin-right: 0px;
        margin-bottom: 20px;
    }
    
    .b {
        margin-right: 0px;
        margin-bottom: 20px;
    }
    
    .aboutWrapper {
        display: grid;
        grid-gap: 0px;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }
    #about div img {
        width: 100%;
    }

}