@import url(https://fonts.googleapis.com/css?family=Shadows+Into+Light);
/* Reset */

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
social,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
social,
section {
    display: block;
}

body {}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

body {
    -webkit-text-size-adjust: none;
}


/* Box Model */

*,
*:before,
*:after {
    box-sizing: border-box;
}


/* Basic */

body {
    background-color: #000000 !important;
}

a {
    color: #969B9D;
    font-weight: 900;
}
@font-face {
    font-family: 'dock11regular';
    src: url(/static/media/heavy_dock11-webfont.7d484263.woff2) format('woff2'), url(/static/media/heavy_dock11-webfont.a3bf0c34.woff) format('woff');
    font-weight: normal;
    font-style: normal;
}

#header {
    padding-top: 30px;
    width: 90%;
    margin: 0 auto;
    align-content: center;
    text-align: justify;
    overflow: visible;
    opacity: 0;
}

#header .logo {
    font-family: 'dock11regular';
    font-size: 7.55vw;
    color: #969B9D;
    letter-spacing: 1vw;
}

@media only screen and ( max-width: 600px) {
    #header {
        padding-top: 30px;
    }
}
#nav {
    font-family: 'Helvetica', sans-serif;
    font-weight: 900;
    letter-spacing: .4em;
    padding-top: 40px;
    padding-bottom: 40px;
    width: 90%;
    height: 95px;
    margin: 0 auto;
    margin-top: -1.2vw;
    align-content: center;
    text-align: justify;
    opacity: 0;
}

#nav>a {
    display: none;
}

#nav li {
    position: relative;
}

#nav li a {
    color: #9B0000;
    display: block;
}

#nav li a:active {
    color: #ffffff;
    background-color: #000000 !important;
}

#nav span:after {
    width: 0;
    height: 0;
    border-bottom: none;
    content: '';
    vertical-align: middle;
    display: inline-block;
    position: relative;
    right: -0.313em;
    /* 5 */
}


/* first level */

#nav>ul {
    /* 60 */
    background-color: #000000;
}

#nav>ul>li {
    height: 100%;
    float: left;
}

#nav>ul>li>a {
    font-size: 16px;
    /* 24 */
    line-height: 16px;
    /* 60 (24) */
    text-align: left;
    text-decoration: none;
}

#nav>ul>li:not( :last-child) {
    margin-right: 40px;
}

#nav>ul>li:hover>a,
#nav>ul:not( :hover)>li.active>a {
    background-color: #000000;
}

#social {
    padding-top: 0px;
    padding-bottom: 40px;
    width: 90%;
    height: 80px;
    margin: 0 auto;
    margin-top: 0px;
    align-content: center;
    opacity: 0;
}

#social>a {
    display: none;
}

#social li {
    position: relative;
}

#social li a {
    display: block;
    height: 40px;
    width: 40px;
}

#social li a:active {
    color: #ffffff;
    background-color: #000000 !important;
}

#social span:after {
    width: 0;
    height: 0;
    border-bottom: none;
    content: '';
    vertical-align: middle;
    display: inline-block;
    position: relative;
    right: -0.313em;
    /* 5 */
}

/* first level */

#social>ul {
    /* 60 */
    background-color: #000000;
}

#social>ul>li {
    height: 100%;
    float: left;
}

#social>ul>li>a {
    font-size: 18px;
    /* 24 */
    line-height: 18px;
    /* 60 (24) */
    text-align: left;
    text-decoration: none;
}

#social>ul>li:not( :last-child) {
    margin-right: 40px;
}

#social>ul>li:hover>a,
#social>ul:not( :hover)>li.active>a {
    background-color: #000000;
}
@media only screen and ( max-width: 600px) {
    #nav {
        text-align: center;
        letter-spacing: .2em;
        padding-top: 30px;
        padding-bottom: 30px;
        height: 75px;
    }
    #nav>ul {
        display: inline-block;
    }
    #nav>ul>li>a {
        font-size: 11px;
        line-height: 11px;
        text-align: center;
    }
    #nav>ul>li {
        height: 100%;
        float: center;
    }
    #nav>ul>li:not( :last-child) {
        margin-right: 30px;
    }
    #social {
        text-align: center;
        height: 60px;
    }
    #social li a {
        display: block;
        height: 30px;
        width: 30px;
    }
    #social>ul {
        display: inline-block;
    }
    #social>ul>li {
        height: 100%;
        float: center;
    }
    #social>ul>li:not( :last-child) {
        margin-right: 30px;
    }
}
#content {
    width: 90%;
    margin: 0 auto;
    align-content: center;
    text-align: left;
    overflow: auto;
    opacity: 0;
}
.SandboxRoot.env-bp-970 .timeline-Tweet-text{
font-size: 8px !important;
}

.newsWrapper {
    display: grid;
    grid-gap: 0px;
}

#news {
    margin: 0 auto;
    align-content: center;
    font-family: 'Helvetica', sans-serif;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: .15em;
    font-weight: 500;
    font-style: normal;
    color: #969B9D;
}
#curatorLink {
    font-family: 'Helvetica', sans-serif;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: .15em;
    font-weight: 100;
    font-style: normal;
    color: #969B9D;
}

@media only screen and ( max-width: 555px) {
    
    #content {
        text-align: justify;
    }
    
    .newsWrapper {
        display: grid;
        grid-gap: 0px;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }
}

.aboutWrapper {
    display: grid;
    grid-gap: 0px;
    grid-template-columns: repeat(auto-fit, 250px minmax(250px, 1fr));
}

#about {
    margin: 0 auto;
    align-content: center;
    font-family: 'Helvetica', sans-serif;
    font-size: 10px;
    line-height: 13px;
    letter-spacing: .15em;
    font-weight: 500;
    font-style: normal;
    color: #969B9D;
}

#about div img {
    width: 230px;
    border: 1px solid #969B9D;
}

.box {
    padding: 0px;
    display: block;
    position: relative;
}

.b {
    margin-right: 0px;
    margin-bottom: 40px;
    margin-top: -2px;
}

.a {
    margin-right: 20px;
    margin-bottom: 40px;
}

@media only screen and ( max-width: 555px) {
    .a {
        margin-right: 0px;
        margin-bottom: 20px;
    }
    
    .b {
        margin-right: 0px;
        margin-bottom: 20px;
    }
    
    .aboutWrapper {
        display: grid;
        grid-gap: 0px;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }
    #about div img {
        width: 100%;
    }

}
.discographyWrapper {
    display: grid;
    grid-gap: 0px;
    grid-template-columns: repeat(auto-fit, 250px minmax(250px, 1fr));
}

.boldText {
    font-weight: 700;
}

.box {
    padding: 0px;
    display: block;
    position: relative;
}

.c, .e, .g, .i , .k, .m {
    margin-right: 20px;
    margin-bottom: 40px;
}

.d, .f, .h, .j, .l, .n {
    margin-top: -2px;
    margin-right: 20px;
    margin-bottom: 40px;
}

#discography {
    margin: 0 auto;
    align-content: center;
    font-family: 'Helvetica', sans-serif;
    font-size: 10px;
    line-height: 13px;
    letter-spacing: .15em;
    font-weight: 500;
    font-style: normal;
    color: #969B9D;
}

#discographyTitle {
    font-size: 16px;
    font-family: 'Helvetica', sans-serif;
    font-weight: 900;
    letter-spacing: .4em;
    color: #969B9D;
    padding-top: 0px;
    padding-bottom: 40px;
    align-content: center;
    text-align: justify;
    margin: 0 auto;
}

#discography div img {
    width: 230px;
    border: 1px solid #969B9D;
}


@media only screen and ( max-width: 555px) {
    .c, .e, .g, .i , .k{
        margin-right: 0px;
        margin-bottom: 20px;
    }
    
    .d, .f, .h , .j{
        margin-right: 0px;
    }

    .l {
        margin-bottom: 20px;
    }
    
    .discographyWrapper {
        display: grid;
        grid-gap: 0px;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }
    #discography div img {
        width: 100%;
    }
}
.videoWrapper {
    display: grid;
    grid-gap: 0px;
    grid-template-columns: repeat(auto-fill, 450px);
}

.box {
    padding: 0px;
    display: block;
    position: relative;
}

.v1 {
    margin-right: 40px;
    margin-bottom: 40px;
    width: 409px;
}

.v2 {
    margin-bottom: 40px;
    width: 409px;
}

#video {
    margin: 0 auto;
    align-content: center;
}

#video div img {
    width: 409px;
    border: 1px solid #969B9D;
}


@media only screen and ( max-width: 555px) {
    .v1 {
        margin-right: 0px;
        width: 100%;
        margin-bottom: 20px;
    }

    .v2 {
        width: 100%;
        margin-bottom: 20px;
    }
    
    .videoWrapper {
        display: grid;
        grid-gap: 0px;
        grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    }
    #video div img {
        width: 100%;
    }
}
#footer {
    opacity: 0;
}

.footer {
    margin: 0 auto;
    padding-top: 0px;
    padding-bottom: 40px;
    align-content: center;
    font-family: 'Helvetica', sans-serif;
    font-size: 10px;
    line-height: 13px;
    letter-spacing: .15em;
    font-weight: 500;
    font-style: normal;
    color: #969B9D;
    width: 90%;
}

@media only screen and ( max-width: 600px) {
    .footer {
        padding-top: 20px;
    }
}
