@import url('https://fonts.googleapis.com/css?family=Shadows+Into+Light');

.SandboxRoot.env-bp-970 .timeline-Tweet-text{
font-size: 8px !important;
}

.newsWrapper {
    display: grid;
    grid-gap: 0px;
}

#news {
    margin: 0 auto;
    align-content: center;
    font-family: 'Helvetica', sans-serif;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: .15em;
    font-weight: 500;
    font-style: normal;
    color: #969B9D;
}
#curatorLink {
    font-family: 'Helvetica', sans-serif;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: .15em;
    font-weight: 100;
    font-style: normal;
    color: #969B9D;
}

@media only screen and ( max-width: 555px) {
    
    #content {
        text-align: justify;
    }
    
    .newsWrapper {
        display: grid;
        grid-gap: 0px;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }
}
