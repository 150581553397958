#nav {
    font-family: 'Helvetica', sans-serif;
    font-weight: 900;
    letter-spacing: .4em;
    padding-top: 40px;
    padding-bottom: 40px;
    width: 90%;
    height: 95px;
    margin: 0 auto;
    margin-top: -1.2vw;
    align-content: center;
    text-align: justify;
    opacity: 0;
}

#nav>a {
    display: none;
}

#nav li {
    position: relative;
}

#nav li a {
    color: #9B0000;
    display: block;
}

#nav li a:active {
    color: #ffffff;
    background-color: #000000 !important;
}

#nav span:after {
    width: 0;
    height: 0;
    border-bottom: none;
    content: '';
    vertical-align: middle;
    display: inline-block;
    position: relative;
    right: -0.313em;
    /* 5 */
}


/* first level */

#nav>ul {
    /* 60 */
    background-color: #000000;
}

#nav>ul>li {
    height: 100%;
    float: left;
}

#nav>ul>li>a {
    font-size: 16px;
    /* 24 */
    line-height: 16px;
    /* 60 (24) */
    text-align: left;
    text-decoration: none;
}

#nav>ul>li:not( :last-child) {
    margin-right: 40px;
}

#nav>ul>li:hover>a,
#nav>ul:not( :hover)>li.active>a {
    background-color: #000000;
}

#social {
    padding-top: 0px;
    padding-bottom: 40px;
    width: 90%;
    height: 80px;
    margin: 0 auto;
    margin-top: 0px;
    align-content: center;
    opacity: 0;
}

#social>a {
    display: none;
}

#social li {
    position: relative;
}

#social li a {
    display: block;
    height: 40px;
    width: 40px;
}

#social li a:active {
    color: #ffffff;
    background-color: #000000 !important;
}

#social span:after {
    width: 0;
    height: 0;
    border-bottom: none;
    content: '';
    vertical-align: middle;
    display: inline-block;
    position: relative;
    right: -0.313em;
    /* 5 */
}

/* first level */

#social>ul {
    /* 60 */
    background-color: #000000;
}

#social>ul>li {
    height: 100%;
    float: left;
}

#social>ul>li>a {
    font-size: 18px;
    /* 24 */
    line-height: 18px;
    /* 60 (24) */
    text-align: left;
    text-decoration: none;
}

#social>ul>li:not( :last-child) {
    margin-right: 40px;
}

#social>ul>li:hover>a,
#social>ul:not( :hover)>li.active>a {
    background-color: #000000;
}
@media only screen and ( max-width: 600px) {
    #nav {
        text-align: center;
        letter-spacing: .2em;
        padding-top: 30px;
        padding-bottom: 30px;
        height: 75px;
    }
    #nav>ul {
        display: inline-block;
    }
    #nav>ul>li>a {
        font-size: 11px;
        line-height: 11px;
        text-align: center;
    }
    #nav>ul>li {
        height: 100%;
        float: center;
    }
    #nav>ul>li:not( :last-child) {
        margin-right: 30px;
    }
    #social {
        text-align: center;
        height: 60px;
    }
    #social li a {
        display: block;
        height: 30px;
        width: 30px;
    }
    #social>ul {
        display: inline-block;
    }
    #social>ul>li {
        height: 100%;
        float: center;
    }
    #social>ul>li:not( :last-child) {
        margin-right: 30px;
    }
}