.discographyWrapper {
    display: grid;
    grid-gap: 0px;
    grid-template-columns: repeat(auto-fit, 250px minmax(250px, 1fr));
}

.boldText {
    font-weight: 700;
}

.box {
    padding: 0px;
    display: block;
    position: relative;
}

.c, .e, .g, .i , .k, .m {
    margin-right: 20px;
    margin-bottom: 40px;
}

.d, .f, .h, .j, .l, .n {
    margin-top: -2px;
    margin-right: 20px;
    margin-bottom: 40px;
}

#discography {
    margin: 0 auto;
    align-content: center;
    font-family: 'Helvetica', sans-serif;
    font-size: 10px;
    line-height: 13px;
    letter-spacing: .15em;
    font-weight: 500;
    font-style: normal;
    color: #969B9D;
}

#discographyTitle {
    font-size: 16px;
    font-family: 'Helvetica', sans-serif;
    font-weight: 900;
    letter-spacing: .4em;
    color: #969B9D;
    padding-top: 0px;
    padding-bottom: 40px;
    align-content: center;
    text-align: justify;
    margin: 0 auto;
}

#discography div img {
    width: 230px;
    border: 1px solid #969B9D;
}


@media only screen and ( max-width: 555px) {
    .c, .e, .g, .i , .k{
        margin-right: 0px;
        margin-bottom: 20px;
    }
    
    .d, .f, .h , .j{
        margin-right: 0px;
    }

    .l {
        margin-bottom: 20px;
    }
    
    .discographyWrapper {
        display: grid;
        grid-gap: 0px;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }
    #discography div img {
        width: 100%;
    }
}