@font-face {
    font-family: 'dock11regular';
    src: url('../fonts/heavy_dock11-webfont.woff2') format('woff2'), url('../fonts/heavy_dock11-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

#header {
    padding-top: 30px;
    width: 90%;
    margin: 0 auto;
    align-content: center;
    text-align: justify;
    overflow: visible;
    opacity: 0;
}

#header .logo {
    font-family: 'dock11regular';
    font-size: 7.55vw;
    color: #969B9D;
    letter-spacing: 1vw;
}

@media only screen and ( max-width: 600px) {
    #header {
        padding-top: 30px;
    }
}